import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import theme, { PrimaryColors } from '../utils/theme'
import { Headline5 } from 'stories/elements/Typography/Text'

const Container = styled.div<{ sectionColor: PrimaryColors; showProGradient?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    min-height: 100vh;
    .left-section,
    .right-section {
        display: grid;
        place-content: center;
    }
    .left-section {
        background-color: ${({ sectionColor }) => sectionColor};

        ${({ showProGradient }) =>
            showProGradient
                ? css`
                      background: rgb(82, 240, 141);
                      background: linear-gradient(140deg, rgba(82, 240, 141, 1) 0%, rgba(41, 98, 255, 1) 100%);
                      background-blend-mode: lighten;
                  `
                : ''}
        padding: 5%;
        h5 {
            padding: 10px 15% 0 15%;
        }
    }
    .right-section {
        background-color: ${theme.colors.white};
    }
    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
        min-height: auto;
        .left-section {
            background-color: ${theme.colors.white};
            img {
                width: 200px;
                height: 200px;
            }
            section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
            }
        }
    }
`

interface Props {
    sectionColor: PrimaryColors
    image: ReactNode
    children: ReactNode
    caption?: string
    showProGradient?: boolean
}

const SideSection = ({ sectionColor, image, children, showProGradient, caption }: Props) => {
    return (
        <Container sectionColor={sectionColor} showProGradient={showProGradient}>
            <div className="left-section">
                <section>{image}</section>
                {caption ? (
                    <Headline5
                        fontFamily="var(--font-inter)"
                        fontWeight={500}
                        textAlign="center"
                        color={theme.colors.deepSeaBlue}
                    >
                        {caption}
                    </Headline5>
                ) : null}
            </div>
            <div className="right-section">{children}</div>
        </Container>
    )
}

export default SideSection
