/* eslint-disable @typescript-eslint/no-empty-function */
import { toast } from 'react-toastify'
import Link from 'next/link'
import { useRouter } from 'next/router'
import LoginImage from 'assets/authorization-flow/login.webp'
import Meta from 'components/modules/SEO/Meta'
import AuthEmail from 'components/feature/Authentication/AuthEmail'
import AuthGoogle from 'components/feature/Authentication/AuthGoogle'
import AuthApple from 'components/feature/Authentication/AuthApple'
import { LoginSteps } from 'lib/tracking/attribution/signupattributes'
import styled from 'styled-components'
import { TextSeparator } from 'components/elements/TextSeparator'
import { AuthLinksWrapper } from 'components/feature/Authentication/AuthWrapper'
import { forgotPasswordRoute, signInRoute, signUpRoute } from 'lib/constants/routes'
import { getQueryParamRouter, getReferrerScreen } from 'lib/formatting/url'
import _useTranslation from 'lib/hooks/_useTranslation'
import Stack from 'components/elements/Stack'
import { Body2, StandardHeadline } from 'stories/elements/Typography/Text'
import theme, { PrimaryColors } from 'stories/utils/theme'
import SideSection from 'stories/layouts/SideSection'

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 10px;
    padding: 5%;
    min-height: 100vh;
    width: 500px;
    div {
        width: 450px !important;
        margin: 0;
        @media (max-width: 750px) {
            width: 300px !important;
        }
    }
    .google-icon {
        width: 20px !important;
        padding-right: 40px;
    }
    @media (max-width: 1050px) {
        width: 100%;
        padding: 0%;
    }
`

const BackgroundImage = styled.img``

interface Props {
    handleSuccess: (type: 'email' | 'google' | 'apple') => Promise<void>
    backgroundColor?: PrimaryColors
    title?: string
    sideImage?: StaticImageData
    signUpRedirect?: string
    showProGradient?: boolean
}

function SignInFlow({ handleSuccess, backgroundColor, title, sideImage, signUpRedirect, showProGradient }: Props) {
    const router = useRouter()
    const { t } = _useTranslation('auth')
    const referrerScreen = getReferrerScreen(router)
    const redirect = getQueryParamRouter(router, 'redirect') || signUpRedirect

    const handleNoAccount = () => {
        toast.warn(t('auth/signInNoAccountError'))
        router.push(signInRoute({ redirect, referrerScreen }))
    }

    const imageSource = sideImage || LoginImage

    return (
        <SideSection
            sectionColor={backgroundColor || PrimaryColors.violetBackground}
            image={
                <BackgroundImage src={imageSource.src} width={imageSource.width} height={imageSource.height} alt="Login" />
            }
            showProGradient={showProGradient}
        >
            <Meta title={title || t('auth/signInPageTitle')} description={t('auth/signInPageDescription')} canonical />

            <SectionWrapper>
                <Stack gutter={20} className="form">
                    <StandardHeadline textAlign="center">{title || t('auth/signInPageHeading')}</StandardHeadline>
                    <AuthGoogle
                        type={LoginSteps.SignIn}
                        additionalSignUpAttributes={null}
                        onClick={() => {}}
                        onSuccess={handleSuccess.bind(null, 'google')}
                        handleNoAccount={handleNoAccount}
                    />

                    <AuthApple
                        type={LoginSteps.SignIn}
                        additionalSignUpAttributes={null}
                        onClick={() => {}}
                        onSuccess={handleSuccess.bind(null, 'apple')}
                        handleNoAccount={handleNoAccount}
                    />
                </Stack>
                <TextSeparator>
                    <span />
                    <p>{t('auth/signInRegisterWithLabel')}</p>
                    <span />
                </TextSeparator>
                <Stack gutter={40}>
                    <AuthEmail
                        type={LoginSteps.SignIn}
                        additionalSignUpAttributes={null}
                        onClick={() => {}}
                        onSuccess={handleSuccess.bind(null, 'email')}
                    />

                    <AuthLinksWrapper>
                        <Stack gutter={30}>
                            <Link href={signUpRoute({ redirect, referrerScreen })} passHref prefetch={false} legacyBehavior>
                                <a>
                                    <Body2 color={theme.colors.deepSeaBlue}>{t('auth/signInNewAccount')}</Body2>
                                </a>
                            </Link>
                            <Link href={forgotPasswordRoute({ referrerScreen })} prefetch={false} legacyBehavior>
                                <a>
                                    <Body2 color={theme.colors.deepSeaBlue}>{t('auth/signInForgotPassword')}</Body2>
                                </a>
                            </Link>
                        </Stack>
                    </AuthLinksWrapper>
                </Stack>
            </SectionWrapper>
        </SideSection>
    )
}

export default SignInFlow
